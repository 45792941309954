import getData from "@/__main__/get-data.mjs";
import LolMatch from "@/game-lol/models/lol-match.mjs";
import LolMatchTimeline from "@/game-lol/models/lol-match-timeline.mjs";
import * as API from "@/game-lol/utils/api.mjs";

async function fetchData(params) {
  const [region, matchId] = params;

  await getData(
    API.getMatch(region, Number(matchId)),
    LolMatch,
    ["lol", "matches", `${region.toUpperCase()}_${matchId}`],
    {
      shouldFetchIfPathExists: false,
    },
  );

  getData(
    API.getMatchTimeline(region, matchId),
    LolMatchTimeline,
    ["lol", "matchTimeline", matchId],
    {
      shouldFetchIfPathExists: false,
    },
  );
}

export default fetchData;
